<template>
  <div class="circleJyPerContAdd">
      <batComent :title="title"
        placeholder="搜索"
        :conInfo="conInfo"
        @input="input" 
        @handleClick="handleClick"
        @vanSwipeClick="vanSwipeClick"
        @fileListAdd="fileListAdd"
        :vanSwipeList="vanSwipeList"
        :noDataInfo="noDataInfo"
        :fileList="fileList"
        ></batComent>
  </div>
</template>

<script>
  import batComent from './batComent.vue'
  export default {
    name: 'circleJyPerContAdd',
    components: {
      batComent,
    },
    created(){
      this.title = localStorage.getItem('jyName');
      if (this.title === '添加禁言成员') {
        this.conInfo = '确定禁言'
        this.isTrue = true
      }else {
        this.conInfo = '取消禁言'
        this.isTrue = false
      }
    },
    data() {
      return {
        title: '',
        isTrue: false, // true是确定禁言, false是取消禁言 用来判断
        vanSwipeList:[
          {
            id: 1,
            title: '极氪员工-李三',
            isCheck: true,
            header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
          },
          {
            id: 2,
            title: '极氪员工-李四',
            isCheck: false,
            header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
          },
          {
            id: 3,
            title: '极氪员工-王五',
            isCheck: false,
            header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
          },
          {
            id: 4,
            title: '极氪员工-王五',
            isCheck: false,
            header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
          },
          {
            id: 5,
            title: '极氪员工-王五',
            isCheck: false,
            header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
          },
        ],
        noDataInfo: {
          title1: '暂无相关人员'
        },
        fileList: [
          {
            url: 'https://img01.yzcdn.cn/vant/cat.jpeg'
          }
        ]
      }
    },
    methods: {
      // 回调输入框change
      input(v) {
        console.log(v)
      },
      // 回调输入框click
      handleClick(v) {
        console.log(v)
      },
      // 回调数据
      vanSwipeClick(item) {
         this.vanSwipeList.map((v) => {
          if (item.id === v.id) {
            v.isCheck = !v.isCheck;
          
          }
        })
      },
      // 回调添加
      fileListAdd() {

      }
    }
  }
</script>
 
<style scoped>

</style>